import { Component, OnInit, ChangeDetectionStrategy, inject } from '@angular/core';

import { ReactiveFormsModule, FormGroup, FormControl, Validators, AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { lucideHouse, lucideCircleAlert } from '@ng-icons/lucide';

import { QuoteFormOptionsStore } from 'app/global-store/quote-form-options';
import { MaskitoDirective } from '@maskito/angular';
import { MaskitoOptions } from '@maskito/core';
import { FormCardComponent } from '../../shared/form-card.component';
import { BaseQuoteForm } from '../../../form-config/quote-form-base';
import { QuoteFormContextDecorator } from '../../../form-config/quote-form-decorator';
import { YearBuiltQuoteFormInput } from './year-built.types';
import { QuoteDraftState } from '../../../../global-store/quote-draft/quote-draft.model';
import { TextInputComponent } from '../../../../common/components/forms/text-input/text-input.component';
import { RoofYearQuoteFormInput } from '../roof-year/roof-year.types';
import { PHONE_NUMBERS } from '../../../../common/constants/contact-constants';
import { PhonePipe } from '../../../../common/pipes/phone-number/phone.pipe';
import { fourDigitInputMask } from '../../shared/masks';

@Component({
  selector: 'app-year-built',
  standalone: true,
  imports: [ReactiveFormsModule, NgIconComponent, FormCardComponent, TextInputComponent, MaskitoDirective],
  providers: [provideIcons({ lucideHouse, lucideCircleAlert })],
  templateUrl: './year-built.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@QuoteFormContextDecorator({
  legacyShape: '/quote/property-details/year-built',
})
export class QuoteFormYearBuiltComponent extends BaseQuoteForm<YearBuiltQuoteFormInput> implements OnInit {
  private optionsStore = inject(QuoteFormOptionsStore);

  public formDataIn: YearBuiltQuoteFormInput = this.quoteDraftStore.yearBuiltQuoteFormSelector();
  private roofYear: RoofYearQuoteFormInput = this.quoteDraftStore.roofYearQuoteFormSelector();

  public formDefinition = {
    yearBuilt: new FormControl<number | undefined>(undefined, {
      validators: [Validators.min(1899), Validators.max(new Date().getFullYear() + 1), this.isYearBuiltBeforeRoofYear()],
      updateOn: 'blur',
    }),
    sqFt: new FormControl<number | undefined>(undefined, {
      validators: [Validators.min(10), Validators.max(10000)],
      updateOn: 'blur',
    }),
    stories: new FormControl<number | undefined>(undefined, {
      validators: [Validators.min(1), Validators.max(100)],
      updateOn: 'blur',
    }),
    pool: new FormControl<boolean | undefined>(undefined),
  };
  public yearBuiltForm = new FormGroup(this.formDefinition);

  public yearBuiltOptions = this.optionsStore.yearBuiltQuoteFormOptionsSelector();

  public showError(control: FormControl): boolean {
    return control.invalid && (control.dirty || control.touched);
  }

  readonly fourDigitInputMask: MaskitoOptions = fourDigitInputMask;

  public ngOnInit() {
    if (this.formDataIn) {
      this.yearBuiltForm.controls.yearBuilt.setValue(this.formDataIn.yearBuilt);
      this.yearBuiltForm.controls.sqFt.setValue(this.formDataIn.sqFt);
      this.yearBuiltForm.controls.stories.setValue(this.formDataIn.stories);
      this.yearBuiltForm.controls.pool.setValue(this.formDataIn.pool);
    }

    this.setConditionalRequiredValidator('yearBuilt', 'year_built');
    this.setConditionalRequiredValidator('sqFt', 'sq_ft');
    this.setConditionalRequiredValidator('stories', 'stories');
    this.setConditionalRequiredValidator('pool', 'pool');
  }

  private setConditionalRequiredValidator(controlName: string, optionName: string) {
    const control = this.yearBuiltForm.get(controlName);
    if (control) {
      if (this.hasOption(optionName)) {
        control.addValidators(Validators.required);
      } else {
        control.removeValidators(Validators.required);
      }
      control.updateValueAndValidity();
    }
  }

  private getFormValues(): Pick<QuoteDraftState, 'yearBuilt' | 'sqFt' | 'stories' | 'pool'> {
    const { controls } = this.yearBuiltForm;
    return {
      yearBuilt: controls.yearBuilt.value ?? undefined,
      sqFt: controls.sqFt.value ?? undefined,
      stories: controls.stories.value ?? undefined,
      pool: controls.pool.value ?? undefined,
    };
  }

  public handleSubmit(e: Event) {
    e.preventDefault();
    this.yearBuiltForm.markAllAsTouched();
    if (this.yearBuiltForm.valid) {
      super.saveFormData(this.getFormValues());
    }
  }

  public hasOption(id: string): boolean {
    if (!this.yearBuiltOptions) {
      return false;
    }
    return this.yearBuiltOptions.find((option) => option.id === id) !== undefined;
  }

  private isYearBuiltBeforeRoofYear(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const yearBuilt = Number(control.value);
      const roofYear = this.roofYear?.roofYear;

      if (Number.isNaN(yearBuilt) || !roofYear) {
        return null;
      }

      return yearBuilt < Number(roofYear) ? { yearBuiltBeforeRoofYear: true } : null;
    };
  }

  public errorMessages = {
    yearBuilt: {
      required: 'Please enter the year built',
      min: 'This field is too low',
      max: 'This field is too high',
      yearBuiltBeforeRoofYear: `
      You've entered a year prior to the roof's construction year.  
      If this is incorrect, please call us at 
       <a 
        href="tel:${PHONE_NUMBERS.sales}" 
        class="underline underline-offset-2 outline-none focus:ring-2 focus:ring-primary"
       >
        ${new PhonePipe().transform(PHONE_NUMBERS.sales)}
       </a>.
      `,
    },
    sqFt: {
      required: 'Please enter the number of livable square feet',
      min: 'Livable square feet must be greater than 10',
      max: "Livable square feet can't be more than 10,000",
    },
    stories: {
      required: 'Please enter the number of stories',
      min: 'Number of stories must be greater than 0',
      max: "Number of stories can't be more than 100",
    },
    pool: {
      required: 'Please make a selection',
    },
  };
}
