import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { QuoteFormOptionsStore } from 'app/global-store/quote-form-options';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { lucideHouse, lucideCircleAlert } from '@ng-icons/lucide';
import { QuoteFormContextDecorator } from '../../form-config/quote-form-decorator';
import { ResidencyQuoteFormInput } from './residency.types';
import { QuoteDraftState } from '../../../global-store/quote-draft/quote-draft.model';
import { BaseQuoteForm } from '../../form-config/quote-form-base';
import { FormCardComponent } from '../shared/form-card.component';

@Component({
  selector: 'app-residency',
  standalone: true,
  imports: [ReactiveFormsModule, FormCardComponent, NgIconComponent],
  providers: [provideIcons({ lucideHouse, lucideCircleAlert })],
  templateUrl: './residency.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@QuoteFormContextDecorator({
  legacyShape: '/quote/residency',
})
export class QuoteFormResidencyComponent extends BaseQuoteForm<ResidencyQuoteFormInput> implements OnInit {
  private optionsStore = inject(QuoteFormOptionsStore);
  private _residencyDataIn = this.quoteDraftStore.residency();
  public formDataIn: ResidencyQuoteFormInput = { residency: this._residencyDataIn ? `${this._residencyDataIn}` : undefined };

  public residencyOptions = this.optionsStore.residency();

  public formDefinition = {
    residency: new FormControl<string | undefined>(undefined, Validators.required),
  };
  public residencyForm = new FormGroup(this.formDefinition);

  public ngOnInit() {
    if (this.formDataIn) {
      this.residencyForm.controls.residency.setValue(this.formDataIn.residency);
    }
  }

  private getFormValues(): Pick<QuoteDraftState, 'residency'> {
    const { controls } = this.residencyForm;
    const { value } = controls.residency;
    const formValues = {
      residency: value ? parseInt(value, 10) : undefined,
    };

    return formValues;
  }

  public handleSubmit(e: Event) {
    e.preventDefault();
    this.residencyForm.markAllAsTouched();
    if (this.residencyForm.valid) {
      super.saveFormData(this.getFormValues());
    }
  }

  public showFormRequiredError(): boolean {
    const control = this.residencyForm.get('residency');
    return control ? control.invalid && control.touched : false;
  }
}
