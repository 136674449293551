<div class="grid grid-cols-4 gap-400 md:gap-600">
  <div class="col-span-4 flex flex-col gap-300 self-start md:col-span-2 md:col-start-2 lg:col-span-2 lg:col-start-2">
    <app-form-card title="What brings you to Kin today?" icon="lucideGoal">
      <form [formGroup]="insuranceStatusForm" (ngSubmit)="handleSubmit($event)" id="quote-form" class="mt-600">
        <fieldset class="form-group__fieldset">
          <legend class="sr-only">Select your insurance status</legend>
          <div class="grid gap-200 md:grid-cols-4">
            @for (insuranceStatusOption of insuranceStatusOptions; track insuranceStatusOption) {
              <label
                class="radio-card radio-card--block group focus-within:ring-2 focus-within:ring-primary"
                [class.radio-card--invalid]="showError"
                [for]="insuranceStatusOption.id"
                [attr.data-testid]="'insurance-status-' + insuranceStatusOption.id"
              >
                <div class="radio-card__header flex flex-col gap-100">
                  <ng-icon [name]="insuranceStatusOption.icon" size="24" class="radio-card__icon !overflow-visible"></ng-icon>
                  <span class="radio-card__title w-full font-medium leading-200 group-has-[:checked]:font-bold">{{ insuranceStatusOption.label }} </span>
                </div>
                <input class="radio-card__control peer outline-none" type="radio" [id]="insuranceStatusOption.id" [value]="insuranceStatusOption.id" formControlName="insuranceStatus" />
              </label>
            }
          </div>
        </fieldset>
        @if (showError) {
          <div class="fs-body-md fw-medium mt-300 flex items-center gap-100 text-warning" role="alert" data-test-id="form-error-message">
            <ng-icon name="lucideCircleAlert" size="16" class="!overflow-visible" aria-hidden="true"></ng-icon>
            Please make a selection
          </div>
        }
      </form>
    </app-form-card>

    @if (insuranceStatusForm.get('insuranceStatus')?.value === 'new_purchase_new_policy_first_time') {
      <div data-test-id="first-time-buyer-cta" class="border-100 rounded-200 bg-primary-300 px-500 py-300">
        <div class="flex items-start gap-300">
          <ng-icon name="lucideCircleHelp" size="24" class="!overflow-visible [&>svg]:fill-primary" aria-hidden="true"></ng-icon>
          <div class="flex flex-col gap-300">
            <p><strong>Buying insurance for the first time can be confusing.</strong> If you'd like a little extra help, our licensed agents are here for you.</p>
            <div class="hidden md:block">
              <p class="font-bold">(855) 717-0022</p>
              <p>Monday - Friday 7am to 6pm CT, Saturday 8am to 3pm CT</p>
            </div>
          </div>
        </div>

        <a href="tel:8557170022" class="button button--sm button--secondary mt-300 !inline-flex w-full items-center justify-center gap-100 text-center md:hidden">
          <ng-icon name="lucidePhone" size="16" class="!overflow-visible" aria-hidden="true"></ng-icon>
          Talk to an agent
        </a>
      </div>
    }
  </div>
</div>
