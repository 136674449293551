import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgIconComponent, provideIcons, provideNgIconLoader } from '@ng-icons/core';
import { lucideCircleAlert, lucideCircleHelp, lucideHouse, lucidePhone } from '@ng-icons/lucide';
import { QuoteFormOptionsStore } from 'app/global-store/quote-form-options';
import { HttpClient } from '@angular/common/http';
import { QuoteDraftState } from '../../../../global-store/quote-draft/quote-draft.model';
import { BaseQuoteForm } from '../../../form-config/quote-form-base';
import { QuoteFormContextDecorator } from '../../../form-config/quote-form-decorator';
import { FormCardComponent } from '../../shared/form-card.component';
import { FoundationTypeQuoteFormInput, FoundationTypeValue } from './foundation.types';

@Component({
  selector: 'app-foundation',
  standalone: true,
  imports: [FormCardComponent, NgIconComponent, ReactiveFormsModule],
  templateUrl: './foundation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [
    provideIcons({ lucideCircleAlert, lucideCircleHelp, lucideHouse, lucidePhone }),
    provideNgIconLoader((filename) => {
      const http = inject(HttpClient);
      return http.get(`/assets/icons/${filename}`, { responseType: 'text' });
    }),
  ],
})
@QuoteFormContextDecorator({
  legacyShape: '/quote/property-details/foundation',
})
export class QuoteFormFoundationTypeComponent extends BaseQuoteForm<FoundationTypeQuoteFormInput> implements OnInit {
  private optionsStore = inject(QuoteFormOptionsStore);
  public formDataIn: FoundationTypeQuoteFormInput = this.quoteDraftStore.foundationTypeQuoteFormSelector();

  public foundationTypeOptions = this.optionsStore.foundationTypeQuoteFormOptionsSelector();

  public formDefinition = {
    foundationType: new FormControl<FoundationTypeValue | undefined>(undefined, Validators.required),
  };
  public foundationTypeForm = new FormGroup(this.formDefinition);

  public ngOnInit() {
    if (this.formDataIn.foundationType) {
      this.foundationTypeForm.controls.foundationType.setValue(this.formDataIn.foundationType);
    }
  }

  private getFormValues(): Pick<QuoteDraftState, 'foundationType'> {
    const { controls } = this.foundationTypeForm;
    const formValues = {
      foundationType: controls.foundationType.value ?? undefined,
    };

    return formValues;
  }

  public handleSubmit(e: Event) {
    e.preventDefault();
    this.foundationTypeForm.markAllAsTouched();
    if (this.foundationTypeForm.valid) {
      super.saveFormData(this.getFormValues());
    }
  }

  public get hasError() {
    const control = this.foundationTypeForm.get('foundationType');
    return !!control?.invalid && !!control?.touched;
  }
}
