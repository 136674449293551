import { AppApiRootList, AppEnvironment } from '../app-config.model';

/**
 * production is the default and is used in the app-bootstrap-factory as the default,
 * so it needs to be exposed separately.
 */
export const quotingProdApiHost = 'https://app.kin.insure';
export const quotingProdApiRoot = `${quotingProdApiHost}/api/v1`;

export const quotingAppApiConfig: ReadonlyMap<AppEnvironment, AppApiRootList> = new Map([
  [
    'local',
    {
      quoteV1: 'https://rift-pr-34268-app.dev.kin.co/api/v1',
    },
  ],
  [
    'development',
    {
      quoteV1: 'https://rift-pr-34268-app.dev.kin.co/api/v1',
    },
  ],
  [
    'staging',
    {
      quoteV1: 'https://rift-pr-34268-app.dev.kin.co/api/v1',
    },
  ],
  [
    'production',
    {
      quoteV1: quotingProdApiRoot,
    },
  ],
]);
