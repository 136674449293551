<div class="grid grid-cols-4 gap-400 md:gap-600">
  <form [formGroup]="unitNumberCheckForm" (submit)="handleSubmit($event)" id="quote-form" class="col-span-4 flex w-full flex-col gap-400 self-start sm:col-span-2 sm:col-start-2">
    <app-form-card title="Does your address have a unit number?" icon="lucideHouse">
      <div class="mt-6 flex flex-row gap-100 sm:mt-10 sm:gap-400">
        <label class="radio-card radio-card--block focus-within:ring-2 focus-within:ring-primary" [class.radio-card--invalid]="showFormRequiredError">
          <input class="radio-card__control peer outline-none" type="radio" id="unit-number-check-yes" [value]="true" formControlName="hasUnitNumber" />
          <span for="unit-number-check-yes">Yes</span>
        </label>
        <label class="radio-card radio-card--block focus-within:ring-2 focus-within:ring-primary" [class.radio-card--invalid]="showFormRequiredError">
          <input class="radio-card__control peer outline-none" type="radio" id="unit-number-check-no" [value]="false" formControlName="hasUnitNumber" />
          <span for="unit-number-check-no">No</span>
        </label>
      </div>

      <div aria-live="polite" aria-atomic="true">
        @if (showFormRequiredError) {
          <div class="form-help form-help--invalid flex items-center gap-100">
            <ng-icon name="lucideCircleAlert" aria-hidden="true" class="!overflow-visible"></ng-icon>
            <span class="error-message">Please select a response.</span>
          </div>
        }
      </div>
    </app-form-card>
  </form>
</div>
