<div class="grid grid-cols-4 gap-400 md:gap-600">
  <form [formGroup]="policyStartDateForm" class="col-span-4 flex flex-col gap-400 self-start md:col-span-2 md:col-start-2 lg:col-span-2 lg:col-start-2" (submit)="handleSubmit($event)" id="quote-form">
    <app-form-card [title]="'When would you like your insurance coverage to begin?'" icon="lucideGoal">
      <div class="mt-600 grid grid-cols-1 gap-x-400 gap-y-400 md:gap-y-600">
        <fieldset class="form-group__fieldset">
          <legend class="sr-only">Select your policy start date</legend>
          <app-text-input
            id="policy-start-date"
            type="date"
            [required]="true"
            [control]="policyStartDateForm.controls.policyStartDate"
            [min]="minDate"
            [max]="maxDate"
            helpText="Please choose a date within the next 60 days."
            [errorMessages]="errorMessages.policyStartDate"
            class="sm:col-span-1"
          ></app-text-input>
        </fieldset>
      </div>
    </app-form-card>
  </form>
</div>
