import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MaskitoDirective } from '@maskito/angular';
import { MaskitoOptions } from '@maskito/core';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { lucideCircleAlert, lucideCircleHelp, lucideHouse } from '@ng-icons/lucide';
import { TextInputComponent } from 'app/common/components/forms/text-input/text-input.component';
import { BaseQuoteForm } from 'app/create-quote/form-config/quote-form-base';
import { QuoteFormContextDecorator } from 'app/create-quote/form-config/quote-form-decorator';
import { FormCardComponent } from 'app/create-quote/forms/shared/form-card.component';
import { QuoteDraftState } from 'app/global-store/quote-draft/quote-draft.model';
import { QuoteFormOptionsStore } from 'app/global-store/quote-form-options';
import { PHONE_NUMBERS } from '../../../../common/constants/contact-constants';
import { PhonePipe } from '../../../../common/pipes/phone-number/phone.pipe';
import { fourDigitInputMask } from '../../shared/masks';
import { RoofYearQuoteFormInput } from './roof-year.types';

@Component({
  selector: 'app-roof-year',
  standalone: true,
  imports: [ReactiveFormsModule, FormCardComponent, NgIconComponent, TextInputComponent, PhonePipe, MaskitoDirective],
  providers: [provideIcons({ lucideHouse, lucideCircleAlert, lucideCircleHelp })],
  templateUrl: './roof-year.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@QuoteFormContextDecorator({
  legacyShape: '/quote/property-details/roof-year',
})
export class QuoteFormRoofYearComponent extends BaseQuoteForm<RoofYearQuoteFormInput> implements OnInit {
  public formDataIn: RoofYearQuoteFormInput = this.quoteDraftStore.roofYearQuoteFormSelector();
  public phoneNumber = PHONE_NUMBERS.sales;
  private optionsStore = inject(QuoteFormOptionsStore);
  public roofYearValidations = this.optionsStore.roofYearQuoteFormValidationsSelector();

  public formDefinition = {
    roofYear: new FormControl<number | undefined>(undefined, {
      validators: [Validators.required, Validators.minLength(4), Validators.maxLength(4), this.isRoofYearAfterYearBuilt()],
      updateOn: 'blur',
    }),
  };
  public roofYearForm = new FormGroup(this.formDefinition);

  readonly fourDigitInputMask: MaskitoOptions = fourDigitInputMask;

  public ngOnInit() {
    if (this.formDataIn && this.formDataIn.roofYear) {
      this.roofYearForm.controls.roofYear.setValue(this.formDataIn.roofYear);
    }
  }

  private getFormValues(): Pick<QuoteDraftState, 'roofYear'> {
    const { controls } = this.roofYearForm;
    const formValues = {
      roofYear: controls.roofYear.value ?? undefined,
    };

    return formValues;
  }

  public handleSubmit(e: Event) {
    e.preventDefault();
    this.roofYearForm.markAllAsTouched();
    if (this.roofYearForm.valid) {
      super.saveFormData(this.getFormValues());
    }
  }

  public isRoofYearAfterYearBuilt(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const roofYear = Number(control.value);
      const yearBuilt = this.formDataIn?.yearBuilt;
      const pasYearBuilt = this.roofYearValidations?.minValue;

      // Delegate isNumber validator, ignore if roof year is unknown
      if (Number.isNaN(roofYear) || (yearBuilt === undefined && pasYearBuilt === undefined)) {
        return null;
      }

      // Edge Case: User enters yearBuilt even though Maestro has the data (which is returned via /pages API as the pasYearBuilt)
      // Find the minimum year between yearBuilt and pasYearBuilt if both are available, otherwise use whichever one is not undefined
      const minYear = Math.min(yearBuilt ?? Infinity, pasYearBuilt ?? Infinity);

      // Check if roofYear is before the minimum year
      return roofYear < minYear ? { roofYearBeforeYearBuilt: true } : null;
    };
  }
}
