<div class="grid grid-cols-4 gap-400 md:gap-600">
  <form [formGroup]="formGrp" (ngSubmit)="handleSubmit($event)" id="quote-form" class="col-span-4 flex flex-col gap-400 self-start md:col-span-2 md:col-start-2 lg:col-span-2 lg:col-start-2">
    <app-form-card title="Did your insurance expire over 30 days ago?" icon="lucideHouse">
      <div class="mt-600 grid grid-cols-1 gap-x-400 gap-y-400 md:gap-y-600">
        <fieldset class="form-group__fieldset">
          <legend class="sr-only">Did your insurance expire over 30 days ago?</legend>

          <div class="grid grid-cols-2 gap-200 text-center">
            <label class="radio-card radio-card--block group focus-within:ring-2 focus-within:ring-primary" [class.radio-card--invalid]="showFormRequiredError()">
              <div class="radio-card__header flex flex-col gap-100">
                <span class="radio-card__title w-full font-medium leading-200 group-has-[:checked]:font-bold">Yes</span>
              </div>
              <input [value]="true" class="radio-card__control peer outline-none" type="radio" formControlName="insuranceExpired" />
            </label>
            <label class="radio-card radio-card--block group focus-within:ring-2 focus-within:ring-primary" [class.radio-card--invalid]="showFormRequiredError()">
              <div class="radio-card__header flex flex-col gap-100">
                <span class="radio-card__title w-full font-medium leading-200 group-has-[:checked]:font-bold">No</span>
              </div>
              <input [value]="false" class="radio-card__control peer outline-none" type="radio" formControlName="insuranceExpired" />
            </label>
          </div>
        </fieldset>

        @if (showFormRequiredError()) {
          <div class="fs-body-md fw-medium error-message flex items-center gap-100 text-warning" role="alert" data-test-id="form-error-message">
            <ng-icon name="lucideCircleAlert" size="16" class="!overflow-visible" aria-hidden="true"></ng-icon>
            Please make a selection
          </div>
        }
      </div>
    </app-form-card>
  </form>
</div>
