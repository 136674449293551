<div class="grid grid-cols-4 gap-400 md:gap-600">
  <div class="col-span-4 flex flex-col gap-400 self-start md:col-span-2 md:col-start-2 lg:col-span-2 lg:col-start-2">
    <form [formGroup]="propertyTypeForm" (ngSubmit)="handleSubmit($event)" id="quote-form">
      <app-form-card [title]="'What type of property do you want to insure?'" icon="lucideHouse">
        <div class="mt-600 grid grid-cols-1 gap-x-400 gap-y-400 md:gap-y-600">
          <fieldset class="form-group__fieldset">
            <legend class="sr-only">Select your property type</legend>
            <div class="grid gap-200 md:grid-cols-2">
              @for (propertyTypeOption of propertyTypeOptions; track propertyTypeOption) {
                <label
                  class="radio-card radio-card--block group focus-within:ring-2 focus-within:ring-primary"
                  [class.radio-card--invalid]="showError"
                  [for]="propertyTypeOption.id"
                  [attr.data-testid]="'property-type-' + propertyTypeOption.id"
                >
                  <div class="radio-card__header flex flex-col gap-100">
                    <div class="flex w-full items-center justify-start">
                      <ng-icon [name]="propertyTypeOption.icon" size="30" class="radio-card__icon !overflow-visible text-black"></ng-icon>
                      <p class="radio-card__title m-l-200 font-medium group-has-[:checked]:font-bold">{{ propertyTypeOption.label }}</p>
                    </div>
                    <div class="w-full">
                      <p class="text-sm">{{ propertyTypeOption.description }}</p>
                    </div>
                  </div>
                  <input class="radio-card__control peer outline-none" type="radio" [id]="propertyTypeOption.id" [value]="propertyTypeOption.id" formControlName="propertyType" />
                </label>
              }
            </div>
          </fieldset>
          @if (showError) {
            <div class="fs-body-md fw-medium error-message flex items-center gap-100 text-warning" role="alert" data-test-id="form-error-message" aria-live="polite" aria-atomic="true">
              <ng-icon name="lucideCircleAlert" size="16" class="!overflow-visible" aria-hidden="true"></ng-icon>
              Please make a selection
            </div>
          }
        </div>
      </app-form-card>
    </form>
  </div>
</div>
