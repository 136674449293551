<div class="grid grid-cols-4 gap-200 md:gap-600">
  <div class="col-span-4 flex flex-col gap-300 self-start md:col-span-2 md:col-start-2 lg:col-span-2 lg:col-start-2">
    <app-form-card title="We have a few more questions about your property." icon="lucideHouse">
      <form [formGroup]="yearBuiltForm" (ngSubmit)="handleSubmit($event)" id="quote-form" class="mt-600">
        <div class="flex flex-col gap-600">
          @if (hasOption('year_built')) {
            <app-text-input
              [control]="yearBuiltForm.controls.yearBuilt"
              label="What year was this home built?"
              id="year-built"
              type="text"
              inputmode="numeric"
              [maskito]="fourDigitInputMask"
              maxlength="4"
              pattern="\d{4}"
              min="1"
              [required]="true"
              [errorMessages]="errorMessages.yearBuilt"
            ></app-text-input>
          }

          @if (hasOption('sq_ft')) {
            <app-text-input
              class="sm:col-span-3"
              min="1"
              [control]="yearBuiltForm.controls.sqFt"
              label="What is the number of livable square feet?"
              id="sq-ft"
              type="number"
              [required]="true"
              [errorMessages]="errorMessages.sqFt"
            ></app-text-input>
          }

          @if (hasOption('stories')) {
            <app-text-input
              class="sm:col-span-3"
              [control]="yearBuiltForm.controls.stories"
              label="How many stories does your property have?"
              id="stories"
              type="number"
              min="0"
              [required]="true"
              [errorMessages]="errorMessages.stories"
            ></app-text-input>
          }

          @if (hasOption('pool')) {
            <fieldset class="form-group__fieldset">
              <legend [class]="'input__label ' + (yearBuiltForm.controls.pool.touched ? 'text-neutral-900' : 'text-neutral-700')" [class.text-warning]="showError(yearBuiltForm.controls.pool)">
                Do you have a swimming pool?
              </legend>

              <div class="mt-3 flex flex-row gap-100 sm:gap-400">
                <label class="radio-card radio-card--block focus-within:ring-2 focus-within:ring-primary" data-testid="pool-yes" [class.radio-card--invalid]="showError(yearBuiltForm.controls.pool)">
                  <input class="radio-card__control peer outline-none" type="radio" id="pool-yes" [value]="true" formControlName="pool" />
                  <span for="pool-yes">Yes</span>
                </label>
                <label class="radio-card radio-card--block focus-within:ring-2 focus-within:ring-primary" data-testid="pool-no" [class.radio-card--invalid]="showError(yearBuiltForm.controls.pool)">
                  <input class="radio-card__control peer outline-none" type="radio" id="pool-no" [value]="false" formControlName="pool" />
                  <span for="pool-no">No</span>
                </label>
              </div>

              <div id="'pool-message'" [class]="'form-help ' + (yearBuiltForm.controls.pool.touched ? 'text-neutral-900' : 'text-neutral-700')" aria-live="polite" aria-atomic="true">
                @if (showError(yearBuiltForm.controls.pool)) {
                  <div class="form-help--invalid flex items-center gap-100" id="pool-error">
                    <ng-icon name="lucideCircleAlert" class="!overflow-visible" aria-hidden="true"></ng-icon>
                    This field is required
                  </div>
                }
              </div>
            </fieldset>
          }
        </div>
      </form>
    </app-form-card>
  </div>

  <app-form-card class="col-span-4 self-start md:col-span-1">
    <div class="flex flex-col gap-200">
      <h2 class="flex flex-col gap-300 text-100 font-bold leading-[1.375]">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="var(--color-primary)"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          class="h-[2em] w-[2em]"
          viewBox="0 0 24 24"
        >
          <path d="M7.9 20A9 9 0 1 0 4 16.1L2 22Z" />
          <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3M12 17h.01" />
        </svg>
        Why are we asking for this?
      </h2>
      <p>We'll use your property info plus additional data from our trusted sources to build you the most accurate quote.</p>
    </div>
  </app-form-card>
</div>
