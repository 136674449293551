<div class="container pt-300 md:pt-800">
  <div class="grid grid-cols-4 gap-400 md:gap-600">
    <form [formGroup]="rentalNumberForm" (submit)="handleSubmit($event)" id="quote-form" class="col-span-4 flex w-full flex-col gap-400 self-start sm:col-span-2 sm:col-start-2">
      <app-form-card title="How many times do you plan to rent out your property in the next 12 months?" icon="lucideHouse">
        <select formControlName="rentalNumber" class="select__control mt-10" [required]="true">
          <option disabled [value]="undefined">Planned number of times to rent:</option>
          <option [value]="0">0</option>
          <option [value]="5">1-5</option>
          <option [value]="9">6-9</option>
          <option [value]="10">10+</option>
        </select>

        <div aria-live="polite" aria-atomic="true">
          @if (showFormRequiredError()) {
            <div class="form-help form-help--invalid flex items-center gap-100">
              <ng-icon name="lucideCircleAlert" aria-hidden="true" class="!overflow-visible"></ng-icon>
              <span class="error-message">Please select an annual rental frequency.</span>
            </div>
          }
        </div>
      </app-form-card>
    </form>
  </div>
</div>
