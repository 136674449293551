import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { lucideCircleAlert, lucideHouse } from '@ng-icons/lucide';
import { TextInputComponent } from '../../../common/components/forms/text-input/text-input.component';
import { QuoteDraftState } from '../../../global-store/quote-draft/quote-draft.model';
import { BaseQuoteForm } from '../../form-config/quote-form-base';
import { QuoteFormContextDecorator } from '../../form-config/quote-form-decorator';
import { FormCardComponent } from '../shared/form-card.component';
import { UnitNumberEntryQuoteFormInput } from './unit-number-entry.types';

@Component({
  selector: 'app-unit-number-entry',
  standalone: true,
  imports: [ReactiveFormsModule, NgIconComponent, FormCardComponent, TextInputComponent],
  templateUrl: './unit-number-entry.component.html',
  providers: [provideIcons({ lucideHouse, lucideCircleAlert })],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@QuoteFormContextDecorator({
  legacyShape: '/quote/unit_number_entry',
})
export class QuoteFormUnitNumberEntryComponent extends BaseQuoteForm<UnitNumberEntryQuoteFormInput> implements OnInit {
  public formDataIn: UnitNumberEntryQuoteFormInput = this.quoteDraftStore.unitNumberEntryQuoteFormSelector();

  public formDefinition = {
    unitNumber: new FormControl<string | undefined>(undefined, Validators.required),
  };

  public unitNumberEntryForm = new FormGroup(this.formDefinition);

  // @todo - Update unitNumber in address so displayAddress selector has the correct unit number (e.g., for footnote address display)
  // private storeAddress = this.quoteDraftStore.address();

  public showFormRequiredError(): boolean {
    const control = this.unitNumberEntryForm.get('unitNumber');
    return !!control?.touched && control?.value === undefined;
  }

  public ngOnInit() {
    if (this.formDataIn) {
      this.unitNumberEntryForm.controls.unitNumber.setValue(this.formDataIn.unitNumber);
    }
  }

  private getFormValues(): Pick<QuoteDraftState, 'unitNumber'> {
    const { controls } = this.unitNumberEntryForm;
    const formValues = {
      unitNumber: controls.unitNumber.value ?? undefined,
    };

    return formValues;
  }

  public handleSubmit(e: Event) {
    e.preventDefault();
    this.unitNumberEntryForm.markAllAsTouched();
    if (this.unitNumberEntryForm.valid) {
      // Update unitNumber in store
      super.saveFormData(this.getFormValues());

      // @todo - Update unitNumber in address so displayAddress selector has the correct unit number (e.g., for footnote address display)
      // if (this.storeAddress) {
      //   this.storeAddress = { ...this.storeAddress, unit: this.unitNumberEntryForm.controls.unitNumber.value ?? undefined };
      // }
    }
  }

  public errorMessages = {
    unitNumber: {
      required: 'Please enter your unit number.',
    },
  };
}
