<div class="grid grid-cols-4 gap-400 md:gap-600">
  <app-form-card
    title="What year was your roof built or completely replaced?"
    icon="lucideHouse"
    class="col-span-4 flex flex-col gap-300 self-start md:col-span-2 md:col-start-2 lg:col-span-2 lg:col-start-2"
  >
    <form [formGroup]="roofYearForm" (ngSubmit)="handleSubmit($event)" class="mt-400 md:mt-600" id="quote-form">
      <app-text-input
        id="roof-year"
        name="roofYear"
        [required]="true"
        type="text"
        inputmode="numeric"
        minlength="4"
        pattern="\d{4}"
        min="0"
        maxlength="4"
        [maskito]="fourDigitInputMask"
        [control]="roofYearForm.controls.roofYear"
      >
        <ng-template>
          @if (roofYearForm.controls.roofYear.errors; as errors) {
            @if (errors['required']) {
              Please enter a roof year
            } @else if (errors['minlength'] || errors['pattern']) {
              Please enter a valid 4-digit year
            } @else if (errors['roofYearBeforeYearBuilt']) {
              <span>
                You've entered a roof year prior to the house's construction year. If this is incorrect, please call us at
                <a href="tel:{{ phoneNumber }}" class="underline underline-offset-2 outline-none focus:ring-2 focus:ring-primary">{{ phoneNumber | phone }}</a
                >.
              </span>
            } @else {
              An error occurred.
            }
          }
        </ng-template>
      </app-text-input>
    </form>
  </app-form-card>

  <app-form-card class="col-span-4 self-start md:col-span-1">
    <div class="flex flex-col gap-100">
      <h2 class="flex flex-col gap-300 text-100 font-bold leading-[1.375]">
        <svg xmlns="http://www.w3.org/2000/svg" fill="var(--color-primary)" viewBox="0 0 32 32" class="h-[2em] w-[2em]">
          <path
            fill="var(--color-primary)"
            stroke="var(--color-neutral-800)"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M10.534 26.666a12 12 0 1 0-5.2-5.2l-2.667 7.867 7.867-2.667Z"
          />
          <path fill="var(--color-primary)" d="M12.12 12a4 4 0 0 1 7.773 1.333c0 2.667-4 4-4 4" />
          <path stroke="var(--color-neutral-800)" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12.12 12a4 4 0 0 1 7.773 1.333c0 2.667-4 4-4 4" />
          <path fill="var(--color-primary)" d="M16 22.667h.013H16Z" />
          <path stroke="var(--color-neutral-800)" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 22.667h.013" />
        </svg>
        Why are we asking for this?
      </h2>
      <p>We'll use your property info and gather additional data from our trusted sources to build you the most accurate quote.</p>
    </div>
  </app-form-card>
</div>
