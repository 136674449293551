<div class="grid grid-cols-4 gap-200 md:gap-600">
  <div class="col-span-4 flex flex-col gap-300 self-start md:col-span-2 md:col-start-2 lg:col-span-2 lg:col-start-2">
    <app-form-card title="We have a few more questions about your property." icon="lucideHouse">
      <form [formGroup]="propertyInformationForm" (ngSubmit)="handleSubmit($event)" id="quote-form" class="mt-600">
        <div class="flex flex-col gap-600">
          @if (hasOption('within_park')) {
            <fieldset class="form-group__fieldset">
              <legend
                [class]="'input__label ' + (propertyInformationForm.controls.withinPark.touched ? 'text-neutral-900' : 'text-neutral-700')"
                [class.text-warning]="showError(propertyInformationForm.controls.withinPark)"
              >
                Is your home in a manufactured home park?
              </legend>

              <div class="mt-3 flex flex-row gap-100 sm:gap-400">
                <label class="radio-card radio-card--block focus-within:ring-2 focus-within:ring-primary" [class.radio-card--invalid]="showError(propertyInformationForm.controls.withinPark)">
                  <input class="radio-card__control peer outline-none" type="radio" id="within-park-yes" [value]="true" formControlName="withinPark" />
                  <span for="within-park-yes">Yes</span>
                </label>
                <label class="radio-card radio-card--block focus-within:ring-2 focus-within:ring-primary" [class.radio-card--invalid]="showError(propertyInformationForm.controls.withinPark)">
                  <input class="radio-card__control peer outline-none" type="radio" id="within-park-no" [value]="false" formControlName="withinPark" />
                  <span for="within-park-no">No</span>
                </label>
              </div>

              <div
                id="within-park-message"
                [class]="'form-help ' + (propertyInformationForm.controls.withinPark.touched ? 'text-neutral-900' : 'text-neutral-700')"
                aria-live="polite"
                aria-atomic="true"
              >
                @if (showError(propertyInformationForm.controls.withinPark)) {
                  <div class="form-help--invalid flex items-center gap-100" id="within-park-error">
                    <ng-icon name="lucideCircleAlert" class="!overflow-visible" aria-hidden="true"></ng-icon>
                    Please select an option
                  </div>
                }
              </div>
            </fieldset>
          }

          @if (hasOption('bathrooms')) {
            <app-text-input
              id="bathrooms"
              label="How many bathrooms are in the main dwelling of your property?"
              type="number"
              [control]="propertyInformationForm.controls.bathrooms"
              min="0"
              step="1"
              [errorMessages]="errorMessages.bathrooms"
              [required]="true"
              helpText='Please include both full and half bathrooms. For example, if you have 1 full bath and one half bath, you would answer "2".'
            />
          }

          @if (hasOption('roof_solar_panels')) {
            <fieldset class="form-group__fieldset">
              <legend
                [class]="'input__label ' + (propertyInformationForm.controls.roofHasSolarPanels.touched ? 'text-neutral-900' : 'text-neutral-700')"
                [class.text-warning]="showError(propertyInformationForm.controls.roofHasSolarPanels)"
              >
                Does your home have solar panels on the roof?
              </legend>

              <div class="mt-3 flex flex-row gap-100 sm:gap-400">
                <label class="radio-card radio-card--block focus-within:ring-2 focus-within:ring-primary" [class.radio-card--invalid]="showError(propertyInformationForm.controls.roofHasSolarPanels)">
                  <input class="radio-card__control peer outline-none" type="radio" id="roof-solar-panels-yes" [value]="true" formControlName="roofHasSolarPanels" />
                  <span for="roof-solar-panels-yes">Yes</span>
                </label>
                <label class="radio-card radio-card--block focus-within:ring-2 focus-within:ring-primary" [class.radio-card--invalid]="showError(propertyInformationForm.controls.roofHasSolarPanels)">
                  <input class="radio-card__control peer outline-none" type="radio" id="roof-solar-panels-no" [value]="false" formControlName="roofHasSolarPanels" />
                  <span for="roof-solar-panels-no">No</span>
                </label>
              </div>

              <div
                id="'roof-solar-panels-message'"
                [class]="'form-help ' + (propertyInformationForm.controls.roofHasSolarPanels.touched ? 'text-neutral-900' : 'text-neutral-700')"
                aria-live="polite"
                aria-atomic="true"
              >
                @if (showError(propertyInformationForm.controls.roofHasSolarPanels)) {
                  <div class="form-help--invalid flex items-center gap-100" id="roof-solar-panels-error">
                    <ng-icon name="lucideCircleAlert" class="!overflow-visible" aria-hidden="true"></ng-icon>
                    Please select an option
                  </div>
                }
              </div>
            </fieldset>
          }

          @if (hasOption('acreage')) {
            <app-text-input
              id="acreage"
              label="How many acres of land does this property include?"
              type="number"
              [control]="propertyInformationForm.controls.acreage"
              min="0"
              step="1"
              [required]="true"
              [errorMessages]="errorMessages.acreage"
            />
          }

          @if (hasOption('purchase_date')) {
            <app-text-input
              id="purchase-date"
              type="date"
              label="When did you purchase this home?"
              [required]="true"
              [control]="propertyInformationForm.controls.purchaseDate"
              helpText="Please enter the purchase date"
              [errorMessages]="errorMessages.purchaseDate"
            ></app-text-input>
          }

          @if (hasOption('roof_type') && getRoofTypeOptions().length > 0) {
            <div class="form-control">
              <label class="select" [class.select--invalid]="showError(propertyInformationForm.controls.roofType)">
                <span [class]="'select__label ' + (propertyInformationForm.controls.roofType.touched ? 'text-neutral-900' : 'text-neutral-700')">What type of roof does the home have?</span>
                <select formControlName="roofType" class="select__control" required>
                  @for (roofType of getRoofTypeOptions(); track roofType.value) {
                    <option [value]="roofType.value">{{ roofType.label }}</option>
                  }
                </select>
              </label>
              <div class="form-help" aria-live="polite" aria-atomic="true">
                @if (showError(propertyInformationForm.controls.roofType)) {
                  <div class="form-help--invalid flex items-center gap-100" id="roof-type-error">
                    <ng-icon name="lucideCircleAlert" aria-hidden="true" class="!overflow-visible"></ng-icon>
                    Please select a roof type
                  </div>
                }
              </div>
            </div>
          }
        </div>
      </form>
    </app-form-card>
  </div>

  <app-form-card class="col-span-4 self-start md:col-span-1">
    <div class="flex flex-col gap-100">
      <h2 class="flex flex-col gap-300 text-100 font-bold leading-[1.375]">
        <svg xmlns="http://www.w3.org/2000/svg" fill="var(--color-primary)" viewBox="0 0 32 32" class="h-[2em] w-[2em]">
          <path
            fill="var(--color-primary)"
            stroke="var(--color-neutral-800)"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M10.534 26.666a12 12 0 1 0-5.2-5.2l-2.667 7.867 7.867-2.667Z"
          />
          <path fill="var(--color-primary)" d="M12.12 12a4 4 0 0 1 7.773 1.333c0 2.667-4 4-4 4" />
          <path stroke="var(--color-neutral-800)" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12.12 12a4 4 0 0 1 7.773 1.333c0 2.667-4 4-4 4" />
          <path fill="var(--color-primary)" d="M16 22.667h.013H16Z" />
          <path stroke="var(--color-neutral-800)" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 22.667h.013" />
        </svg>
        Why are we asking for this?
      </h2>
      <p>We'll use your property info and gather additional data from our trusted sources to build you the most accurate quote.</p>
    </div>
  </app-form-card>
</div>
