import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { QuoteFormOptionsStore } from 'app/global-store/quote-form-options';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { lucideHouse, lucideCircleAlert } from '@ng-icons/lucide';
import { QuoteFormContextDecorator } from '../../../form-config/quote-form-decorator';
import { RoofShapeQuoteFormInput, RoofShapeValue } from './roof-shape.types';
// eslint-disable-next-line import/no-cycle
import { QuoteDraftState } from '../../../../global-store/quote-draft';
import { BaseQuoteForm } from '../../../form-config/quote-form-base';
import { FormCardComponent } from '../../shared/form-card.component';

@Component({
  selector: 'app-roof-shape',
  standalone: true,
  imports: [ReactiveFormsModule, FormCardComponent, NgIconComponent],
  providers: [provideIcons({ lucideHouse, lucideCircleAlert })],
  templateUrl: './roof-shape.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@QuoteFormContextDecorator({
  legacyShape: '/quote/property-details/roof-shape',
})
export class QuoteFormRoofShapeComponent extends BaseQuoteForm<RoofShapeQuoteFormInput> implements OnInit {
  private optionsStore = inject(QuoteFormOptionsStore);
  public formDataIn: RoofShapeQuoteFormInput = this.quoteDraftStore.roofShapeQuoteFormSelector();

  public roofShapeOptions = this.optionsStore.roofShapeQuoteFormOptionsSelector();

  public roofShapeForm = new FormGroup({
    roofShape: new FormControl<RoofShapeValue | undefined>(undefined, Validators.required),
  });
  public ngOnInit() {
    if (this.formDataIn) {
      this.roofShapeForm.controls.roofShape.setValue(this.formDataIn.roofShape);
    }
  }

  private getFormValues(): Pick<QuoteDraftState, 'roofShape'> {
    const { controls } = this.roofShapeForm;
    const formValues = {
      roofShape: controls.roofShape.value ?? undefined,
    };

    return formValues;
  }

  public handleSubmit(e: Event) {
    e.preventDefault();
    this.roofShapeForm.markAllAsTouched();
    if (this.roofShapeForm.valid) {
      super.saveFormData(this.getFormValues());
    }
  }

  get errorMessage(): string | null {
    const roofShapeControl = this.roofShapeForm.get('roofShape');

    if (!roofShapeControl) {
      return null;
    }

    const hasErrors = roofShapeControl.errors;
    const isTouchedOrDirty = roofShapeControl.touched || roofShapeControl.dirty;

    if (hasErrors && isTouchedOrDirty) {
      return 'This field is required';
    }

    return null;
  }
}
