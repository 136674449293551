import { ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA, effect, inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { MaskitoDirective } from '@maskito/angular';
import { MaskitoOptions } from '@maskito/core';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { lucideCircleUserRound } from '@ng-icons/lucide';
import { NgTemplateOutlet } from '@angular/common';
import { TextInputComponent } from '../../../common/components/forms/text-input/text-input.component';
import { PHONE_NUMBERS } from '../../../common/constants/contact-constants';
import { VALIDATION_CONSTANTS } from '../../../common/constants/validation-constants';
import { PhonePipe } from '../../../common/pipes/phone-number/phone.pipe';
import { QuoteDraftState } from '../../../global-store/quote-draft/quote-draft.model';
import { BaseQuoteForm } from '../../form-config/quote-form-base';
import { QuoteFormContextDecorator } from '../../form-config/quote-form-decorator';
import { ageValidator } from '../shared/age.validator';
import mask from '../shared/date-mask-options';
import { FormCardComponent } from '../shared/form-card.component';
import { AboutYouQuoteFormInput } from './about-you.types';
import { utilToIsoDate } from '../../../common/utils/iso-date-type/to-iso-date';

@Component({
  selector: 'app-about-you',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [MaskitoDirective, FormCardComponent, ReactiveFormsModule, NgIconComponent, TextInputComponent, PhonePipe, RouterModule, NgTemplateOutlet],
  providers: [provideIcons({ lucideCircleUserRound })],
  templateUrl: './about-you.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@QuoteFormContextDecorator({
  legacyShape: '/quote/about-you',
})
export class QuoteFormAboutYouComponent extends BaseQuoteForm<AboutYouQuoteFormInput> {
  public router = inject(Router);
  formDataIn!: AboutYouQuoteFormInput;

  public csPhone = PHONE_NUMBERS.customerSupport;

  readonly maskitoPhoneOptions: MaskitoOptions = {
    mask: ['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  };

  readonly maskitoDateOptions: MaskitoOptions = mask;

  public formDefinition = {
    dob: new FormControl<string>('', {
      validators: [Validators.required, Validators.minLength(10), (control) => ageValidator(control, () => this.quoteDraftStore.address()?.state)],
      updateOn: 'blur',
    }),
    email: new FormControl<string>('', {
      validators: [Validators.required, Validators.email],
      updateOn: 'blur',
    }),
    firstName: new FormControl<string>('', {
      validators: [Validators.required],
      updateOn: 'blur',
    }),
    lastName: new FormControl<string>('', {
      validators: [Validators.required],
      updateOn: 'blur',
    }),
    phoneNumber: new FormControl<string>('', {
      validators: [Validators.required, Validators.pattern(VALIDATION_CONSTANTS.REGEX.phone)],
      updateOn: 'blur',
    }),
    hasOptedIntoSms: new FormControl<boolean>(false),
  };

  public aboutYouComponentForm = new FormGroup(this.formDefinition);

  public errorMessages = {
    firstName: {
      required: 'Your first name is required',
    },
    lastName: {
      required: 'Your last name is required',
    },
    dob: {
      required: 'Your date of birth is required.',
      minlength: 'Please enter a valid date in MM/DD/YYYY format.',
      invalidDateFormat: 'Please enter a valid date in MM/DD/YYYY format.',
      underage: 'Birthdate is outside the eligible range.',
    },
    phoneNumber: {
      required: 'Your phone number is required',
      pattern: 'Please enter a valid phone number',
    },
    email: {
      required: 'Your email address is required',
      pattern: 'Please enter a valid email address',
    },
  };

  // @todo - don't hardcode links in content and use their respective envs (TBD setting up infra)

  constructor() {
    super();
    effect(() => {
      this.formDataIn = this.quoteDraftStore.aboutYouQuoteFormSelector();
      if (this.formDataIn) {
        this.aboutYouComponentForm.patchValue({
          dob: this.formDataIn.dob,
          email: this.formDataIn.email,
          firstName: this.formDataIn.firstName,
          lastName: this.formDataIn.lastName,
          phoneNumber: this.formDataIn.phoneNumber,
          hasOptedIntoSms: this.formDataIn.hasOptedIntoSms,
        });
      }
    });
  }

  public handleSubmit(e: Event) {
    e.preventDefault();
    this.aboutYouComponentForm.markAllAsTouched();
    if (this.aboutYouComponentForm.valid) {
      super.saveFormData(this.getFormValues());
    }
  }

  private getFormValues(): Pick<QuoteDraftState, 'applicant' | 'hasOptedIntoSms'> {
    const { controls } = this.aboutYouComponentForm;
    const dob = controls.dob.value;
    const formattedDob = dob ? utilToIsoDate(dob) : undefined;
    const formValues = {
      applicant: {
        dob: formattedDob,
        email: controls.email.value ?? '',
        firstName: controls.firstName.value ?? '',
        lastName: controls.lastName.value ?? '',
        phoneNumber: controls.phoneNumber.value ?? '',
      },
      hasOptedIntoSms: this.aboutYouComponentForm.controls.hasOptedIntoSms.value ?? false,
    };

    return formValues;
  }

  public isFloridaResident(): boolean {
    return this.quoteDraftStore.address()?.state === 'FL';
  }

  public navigateToResumePage() {
    this.router.navigate(['/resume']);
  }
}
