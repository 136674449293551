import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { lucideCircleAlert, lucideHouse } from '@ng-icons/lucide';
import { QuoteFormContextDecorator } from '../../form-config/quote-form-decorator';
import { BaseQuoteForm } from '../../form-config/quote-form-base';
import { InsuranceExpirationInput } from './insurance-expiration.types';
import { FormCardComponent } from '../shared/form-card.component';

@Component({
  selector: 'app-insurance-expiration',
  standalone: true,
  imports: [ReactiveFormsModule, FormCardComponent, NgIconComponent],
  providers: [provideIcons({ lucideHouse, lucideCircleAlert })],
  templateUrl: './insurance-expiration.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@QuoteFormContextDecorator({
  legacyShape: '/quote/insurance_status_days',
})
export class QuoteFormInsuranceExpirationComponent extends BaseQuoteForm<InsuranceExpirationInput> implements OnInit {
  public formDataIn: InsuranceExpirationInput = this.quoteDraftStore.insuranceExpirationQuoteFormSelector();

  public formDefinition = { insuranceExpired: new FormControl<boolean | undefined>(undefined, Validators.required) };
  public formGrp = new FormGroup(this.formDefinition);

  public ngOnInit(): void {
    const prefillValue = this.formDataIn?.isInsuranceExpiredMoreThan30Days;
    if (prefillValue !== undefined) {
      this.formGrp.controls.insuranceExpired.setValue(prefillValue);
    }
  }

  public handleSubmit(e: Event) {
    e.preventDefault();
    this.formGrp.markAllAsTouched();
    if (this.formGrp.valid && this.formGrp.controls.insuranceExpired.value !== undefined) {
      super.saveFormData({
        isInsuranceExpiredMoreThan30Days: this.formGrp.controls.insuranceExpired.value!,
      });
    }
  }

  public showFormRequiredError(): boolean {
    return this.formGrp.invalid && this.formGrp.touched;
  }
}
