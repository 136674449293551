<div class="grid grid-cols-4 gap-400 md:gap-600">
  <form [formGroup]="roofShapeForm" (ngSubmit)="handleSubmit($event)" id="quote-form" class="col-span-4 flex flex-col gap-400 self-start lg:col-span-2 lg:col-start-2">
    <app-form-card title="What is the shape of your roof?" subcopy="Take a look at your roof and choose the option that best describes its design." icon="lucideHouse">
      <fieldset class="form-group__fieldset mt-400">
        <legend class="sr-only">Select the shape of your roof</legend>
        <div class="grid gap-200 md:grid-cols-3">
          @for (roofShapeOption of roofShapeOptions; track roofShapeOption) {
            <label class="radio-card" class="radio-card radio-card--block group focus-within:ring-2 focus-within:ring-primary" [class.radio-card--invalid]="errorMessage" [for]="roofShapeOption.id">
              <div class="radio-card__header">
                <svg width="36" height="25" viewBox="0 0 36 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_1869_10212)">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M26.3913 12.1331C26.6795 12.1331 26.913 12.3666 26.913 12.6548V23.8142C26.913 24.1024 26.6795 24.336 26.3913 24.336C26.1032 24.336 25.8696 24.1024 25.8696 23.8142V12.6548C25.8696 12.3666 26.1032 12.1331 26.3913 12.1331Z"
                      fill="var(--color-neutral-800)"
                    ></path>
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M8.65217 12.0896C8.94032 12.0896 9.17391 12.3232 9.17391 12.6113V23.7707C9.17391 24.0589 8.94032 24.2925 8.65217 24.2925C8.36403 24.2925 8.13043 24.0589 8.13043 23.7707V12.6113C8.13043 12.3232 8.36403 12.0896 8.65217 12.0896Z"
                      fill="var(--color-neutral-800)"
                    ></path>
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M16.8094 0.897125C17.2124 0.516062 17.831 0.516062 18.2341 0.897125L29.1778 11.2442C29.8865 11.9143 29.4269 13.1331 28.4654 13.1331H6.57804C5.61662 13.1331 5.15699 11.9143 5.8657 11.2442L16.8094 0.897125ZM28.4654 12.0457L17.5217 1.69865L6.57804 12.0457H28.4654Z"
                      fill="var(--color-neutral-800)"
                    ></path>
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M14.3913 16.2465C14.3913 15.6556 14.8696 15.1766 15.4595 15.1766H19.584C20.1739 15.1766 20.6522 15.6556 20.6522 16.2465V24.0329C20.6522 24.3284 20.413 24.5679 20.1181 24.5679C19.8231 24.5679 19.584 24.3284 19.584 24.0329V16.2465H15.4595V24.0329C15.4595 24.3284 15.2204 24.5679 14.9254 24.5679C14.6304 24.5679 14.3913 24.3284 14.3913 24.0329V16.2465Z"
                      fill="var(--color-neutral-800)"
                    ></path>
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M5 24.0896C5 23.8014 5.23359 23.5679 5.52174 23.5679H29.5217C29.8099 23.5679 30.0435 23.8014 30.0435 24.0896C30.0435 24.3777 29.8099 24.6113 29.5217 24.6113H5.52174C5.23359 24.6113 5 24.3777 5 24.0896Z"
                      fill="var(--color-neutral-800)"
                    ></path>
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M17.5217 6.37417C16.6704 6.37417 15.9802 7.06433 15.9802 7.91568C15.9802 8.76702 16.6704 9.45718 17.5217 9.45718C18.3731 9.45718 19.0632 8.76702 19.0632 7.91568C19.0632 7.06433 18.3731 6.37417 17.5217 6.37417ZM14.913 7.91568C14.913 6.47493 16.081 5.30698 17.5217 5.30698C18.9625 5.30698 20.1304 6.47493 20.1304 7.91568C20.1304 9.35642 18.9625 10.5244 17.5217 10.5244C16.081 10.5244 14.913 9.35642 14.913 7.91568Z"
                      fill="var(--color-neutral-800)"
                    ></path>
                  </g>
                  <defs>
                    <clipPath id="clip0_1869_10212"><rect width="26" height="25" fill="white" transform="translate(5)"></rect></clipPath>
                  </defs>
                </svg>
                <span class="radio-card__title">{{ roofShapeOption.label }} </span>
              </div>
              <span class="radio-card__description group-has-[:checked]:font-bold">{{ roofShapeOption.description }}</span>
              <input class="radio-card__control peer outline-none" type="radio" [value]="roofShapeOption.value" [id]="roofShapeOption.id" formControlName="roofShape" />
            </label>
          }
        </div>
      </fieldset>
      @if (errorMessage) {
        <div class="form-help form-help--invalid mt-300 flex items-center gap-100" data-test-id="form-error-message">
          <ng-icon name="lucideCircleAlert" size="16" class="!overflow-visible" aria-hidden="true"></ng-icon>
          Please make a selection
        </div>
      }
    </app-form-card>
  </form>
</div>
