import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgIconComponent, provideIcons, provideNgIconLoader } from '@ng-icons/core';
import { lucideCircleAlert, lucideHouse } from '@ng-icons/lucide';
import { QuoteFormOptionsStore } from 'app/global-store/quote-form-options';
import { QuoteDraftState } from '../../../../global-store/quote-draft/quote-draft.model';
import { BaseQuoteForm } from '../../../form-config/quote-form-base';
import { QuoteFormContextDecorator } from '../../../form-config/quote-form-decorator';
import { FormCardComponent } from '../../shared/form-card.component';
import { PropertyTypeQuoteFormInput, PropertyTypeValue } from './property-type.types';

@Component({
  selector: 'app-property-type',
  standalone: true,
  imports: [FormCardComponent, NgIconComponent, ReactiveFormsModule],
  templateUrl: './property-type.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [
    provideIcons({ lucideCircleAlert, lucideHouse }),
    provideNgIconLoader((name) => {
      const http = inject(HttpClient);
      return http.get(`/assets/icons/${name}.svg`, { responseType: 'text' });
    }),
  ],
})
@QuoteFormContextDecorator({
  legacyShape: '/quote/property-details/property-type',
})
export class QuoteFormPropertyTypeComponent extends BaseQuoteForm<PropertyTypeQuoteFormInput> implements OnInit {
  private optionsStore = inject(QuoteFormOptionsStore);
  public formDataIn: PropertyTypeQuoteFormInput = this.quoteDraftStore.propertyTypeQuoteFormSelector();

  public propertyTypeOptions = this.optionsStore.propertyTypeQuoteFormOptionsSelector();

  public formDefinition = {
    propertyType: new FormControl<PropertyTypeValue | undefined>(undefined, Validators.required),
  };
  public propertyTypeForm = new FormGroup(this.formDefinition);

  public ngOnInit() {
    if (this.formDataIn.propertyType) {
      this.propertyTypeForm.controls.propertyType.setValue(this.formDataIn.propertyType);
    }
  }

  private getFormValues(): Pick<QuoteDraftState, 'propertyType'> {
    const { controls } = this.propertyTypeForm;
    const formValues = {
      propertyType: controls.propertyType.value ?? undefined,
    };

    return formValues;
  }

  public handleSubmit(e: Event) {
    e.preventDefault();
    this.propertyTypeForm.markAllAsTouched();
    if (this.propertyTypeForm.valid) {
      super.saveFormData(this.getFormValues());
    }
  }

  public get showError() {
    const control = this.propertyTypeForm.get('propertyType');
    return !!control?.invalid && !!control?.touched;
  }
}
